$primary: #002F6C;
$primarylight: #0064B6;
$white: #ffffff;
$primarylighter: #A7C6ED;
$secondary: #BA0C2F;
$accent: #8C8C8C;
$bg: #5E5E5E;
$bg1: #F8F9FB;
$bg2: #D9D9D9;
$bg3: #CCCCCC;
$line: #CBCBC8;
$dark: #231F20;
$green: #04AE3E;
$pg: #4A72A0;