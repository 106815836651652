@import "./mediaquery";
@import "./variables";

.home {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100% !important;
  background-color: $bg1;

  .main {
    display: grid;
    grid-template-columns: 225px 1fr;
    width: 100%;
    height: 100%;

    .right_panel {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      overflow: hidden !important;

      .full {
        width: 100%;
        height: 100%;
        overflow-y: auto !important;
      }
    }

    @include maxwidth(mobile) {
      grid-template-columns: 1fr !important;

      .left_panel {
        display: none;
      }
    }
  }
}
